<template>
  <div class="info">
    <top-bar :title="'更多信息'" :left="true"/>
    <div class="headbox">
      <div class="Avatar">
        <img :src="myDetailInfo.headImg" alt="">
      </div>
    </div>
    <div class="">
      <van-cell-group>
        <van-cell   >
          <template #title>
            <div class="blue-box"></div>
            <span class="custom-title">居民信息</span>
          </template>
        </van-cell>
        <van-cell title="姓名" :value="myDetailInfo.name || '未知'" />
        <van-cell title="性别" :value="myDetailInfo.sex || '未知'" />
        <van-cell title="国籍" :value="myDetailInfo.nationalityStr || '未知'" />
        <van-cell title="有效证件号" :value="myDetailInfo.idNumber || '未知'" />
        <van-cell title="手机号码" :value="myDetailInfo.mobile || '未知'" />
        <van-cell title="民族" :value="myDetailInfo.nationStr || '未知'" />
        <van-cell title="出生日期" :value="myDetailInfo.birthday || '未知'" />
        <van-cell title="工作单位" :value="myDetailInfo.company || '未知'" />
        <van-cell title="户籍地址" :value="myDetailInfo.regAddress || '未知'" />
        <van-cell title="现居地" :value="myDetailInfo.nowAddress || '未知'" />
        <van-cell title="紧急联系人" :value="myDetailInfo.emContact || '未知'" />
        <van-cell title="联系人手机号" :value="myDetailInfo.emMobile || '未知'" />
        <!-- <van-cell title="隔间房号" :value="myDetailInfo.buildingRoom || '未知'" /> -->
      </van-cell-group>
      <van-row>
        <van-col span="24">
          <van-button type="info" round block @click="changeInfo">编辑</van-button>
        </van-col>
      </van-row>
    </div>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import TopBar from '../../components/topBar/topBar.vue'

import {getImageStream} from '@/utils/index'
import {desensitization} from '@/utils/utils'
export default {
  components: {
    topBar
  },
  data () {
    return {
      myDetailInfo: {}
    }
  },
  methods: {
    getDetailInfo () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/user/my/jmggDetailInfo'),
        method: 'post',
        params: this.$http.adornParams({
          orgId: this.$orgId,
          userId: this.$globalData.userInfo.userId
        },false)
      }).then(({data})=> {
        if (data.code == 0) {
          this.myDetailInfo = data.userInfoEntity
          this.myDetailInfo.headImg = getImageStream(data.userInfoEntity.headImg)
          this.myDetailInfo.sex = data.userInfoEntity.sex == 1 ?'男':'女'
        } else {
        this.$toast.fail(data.msg);
        }
      })
    },
    changeInfo () {
      this.$dialog.confirm({
        message: '确认编辑？',
      }).then(() => {
        this.$router.push('/my-info-change')
      }).catch(() => {
      });
    }
  },
  created () {
    this.getDetailInfo()
  }
}

    TopBar</script>

<style lang="scss" scoped>
.info {
  padding-top: 100px;
  background-color: #f5f5f5;
}
  .headbox {
    background-color: #fff;
    margin: 30px;
    padding: 30px;
    .Avatar {
      width: 100%;
      text-align: center;
      img {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        overflow: hidden;
      }
    }
  }
  .blue-box {
  background-color: #007AFF;
  height: 35px;
  width: 6px;
  border-radius: 6px;
  float: left;
  margin-top: 8px;
  margin-right: 8px;
}
</style>
